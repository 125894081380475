import * as _jsTokens3 from "js-tokens";

var _jsTokens2 = "default" in _jsTokens3 ? _jsTokens3.default : _jsTokens3;

import * as _helperValidatorIdentifier3 from "@babel/helper-validator-identifier";

var _helperValidatorIdentifier2 = "default" in _helperValidatorIdentifier3 ? _helperValidatorIdentifier3.default : _helperValidatorIdentifier3;

import * as _chalk3 from "chalk";

var _chalk2 = "default" in _chalk3 ? _chalk3.default : _chalk3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = highlight;
exports.getChalk = getChalk;
exports.shouldHighlight = shouldHighlight;
var _jsTokens = _jsTokens2;
var _helperValidatorIdentifier = _helperValidatorIdentifier2;
var _chalk = _chalk2;
const sometimesKeywords = new Set(["as", "async", "from", "get", "of", "set"]);

function getDefs(chalk) {
  return {
    keyword: chalk.cyan,
    capitalized: chalk.yellow,
    jsxIdentifier: chalk.yellow,
    punctuator: chalk.yellow,
    number: chalk.magenta,
    string: chalk.green,
    regex: chalk.magenta,
    comment: chalk.grey,
    invalid: chalk.white.bgRed.bold
  };
}

const NEWLINE = /\r\n|[\n\r\u2028\u2029]/;
const BRACKET = /^[()[\]{}]$/;
let tokenize;
{
  const JSX_TAG = /^[a-z][\w-]*$/i;

  const getTokenType = function (token, offset, text) {
    if (token.type === "name") {
      if ((0, _helperValidatorIdentifier.isKeyword)(token.value) || (0, _helperValidatorIdentifier.isStrictReservedWord)(token.value, true) || sometimesKeywords.has(token.value)) {
        return "keyword";
      }

      if (JSX_TAG.test(token.value) && (text[offset - 1] === "<" || text.slice(offset - 2, offset) == "</")) {
        return "jsxIdentifier";
      }

      if (token.value[0] !== token.value[0].toLowerCase()) {
        return "capitalized";
      }
    }

    if (token.type === "punctuator" && BRACKET.test(token.value)) {
      return "bracket";
    }

    if (token.type === "invalid" && (token.value === "@" || token.value === "#")) {
      return "punctuator";
    }

    return token.type;
  };

  tokenize = function* (text) {
    let match;

    while (match = _jsTokens.default.exec(text)) {
      const token = _jsTokens.matchToToken(match);

      yield {
        type: getTokenType(token, match.index, text),
        value: token.value
      };
    }
  };
}

function highlightTokens(defs, text) {
  let highlighted = "";

  for (const {
    type,
    value
  } of tokenize(text)) {
    const colorize = defs[type];

    if (colorize) {
      highlighted += value.split(NEWLINE).map(str => colorize(str)).join("\n");
    } else {
      highlighted += value;
    }
  }

  return highlighted;
}

function shouldHighlight(options) {
  return !!_chalk.supportsColor || options.forceColor;
}

function getChalk(options) {
  return options.forceColor ? new _chalk.constructor({
    enabled: true,
    level: 1
  }) : _chalk;
}

function highlight(code, options = {}) {
  if (code !== "" && shouldHighlight(options)) {
    const chalk = getChalk(options);
    const defs = getDefs(chalk);
    return highlightTokens(defs, code);
  } else {
    return code;
  }
}

export default exports;
export const __esModule = exports.__esModule;
const _getChalk = exports.getChalk,
      _shouldHighlight = exports.shouldHighlight;
export { _getChalk as getChalk, _shouldHighlight as shouldHighlight };